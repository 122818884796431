<template>
  <v-form v-model="isValid" @submit="onEditClient" :class="$style.form">
    <div :class="$style.container">
      <div :class="$style.avatar">
        <span :class="$style.title">{{ $t('avatar') }}</span>
        <v-file is-disabled-padding color="transparent" @files="onAvatar">
          <v-avatar
            :class="$style.image"
            :src="formData.avatar.content_url"
            :srcset="formData.avatar.preview_url"
            size="lg"
            progress-color="primary"
            :progress-count="progress || 100"
          />
        </v-file>
      </div>
      <text-input
        @input="formData.name = $event"
        :value="formData.name"
        :rules="rules.name"
        :title="$t('name')"
        :placeholder="$t('enter.name')"
        :autofocus="true"
      />
      <text-input
        @input="formData.email = $event"
        :value="formData.email"
        :rules="rules.email"
        :title="$t('email')"
        :placeholder="$t('enter.email')"
        :disabled="!userEmail"
      />
      <div :class="$style.id">
        <span :class="$style.descr">ID</span>
        <span>{{ clientId }}</span>
      </div>
      <VLabeledToggle
        :items="gptVersions"
        @click-item="setGPTVersion"
        :default-index="currentIndex"
      />
    </div>
    <div :class="$style.buttons">
      <v-button size="lg" @click="onCancel">{{ $t('confirm.cancel') }}</v-button>
      <v-button
        :is-disabled="!isValid || pageLoading || !isChanged"
        :is-loading="pageLoading"
        type="submit"
        color="primary"
        size="lg"
        >{{ $t('confirm.save') }}</v-button
      >
    </div>
  </v-form>
</template>

<script>
import Vue from 'vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import VForm from '@elements/VForm.vue'
import VAvatar from '@layouts/avatar/VAvatar.vue'
import TextInput from '@elements/inputs/list/TextInput.vue'
import VButton from '@elements/VButton.vue'
import VFile from '@elements/VFile.vue'

import { vEmail, vLength, vName, vRequired } from '@utils/validations'
import { GptVersionLabels } from '@modules/chat/config'
import { GptVersion } from '@common-types/chat'
import VLabeledToggle from '@elements/VLabeledToggle.vue'

export default Vue.extend({
  name: 'ClientEdit',
  components: {
    VLabeledToggle,
    VForm,
    VAvatar,
    TextInput,
    VButton,
    VFile,
  },
  data() {
    return {
      isValid: false,
      formData: {
        avatar_id: null,
        avatar: {
          content_url: null,
          preview_url: null,
        },
        name: null,
        email: null,
      },
    }
  },
  mounted() {
    this.setPageTitle({ name: 'navigation.profileEdit' })
    this.formData.name = this.clientName
    this.formData.email = this.userEmail
    this.formData.avatar.content_url = this.clientAvatar('content_url')
    this.formData.avatar.preview_url = this.clientAvatar('preview_url')
  },
  computed: {
    ...mapGetters('Client', ['clientId', 'clientAvatar', 'clientName', 'gptVersion']),
    ...mapGetters('User', ['user', 'userEmail']),
    ...mapGetters('Media', ['mediaIds']),
    ...mapGetters('Files', ['files', 'fileLoadParams', 'fileProgress']),
    ...mapGetters('Page', ['pageLoading']),
    gptVersions() {
      return [
        { label: GptVersionLabels[GptVersion.GPT_3], value: GptVersion.GPT_3 },
        { label: GptVersionLabels[GptVersion.GPT_4], value: GptVersion.GPT_4 },
      ]
    },
    currentIndex() {
      return this.gptVersions.findIndex(({ value }) => value === this.gptVersion) ?? 0
    },
    rules() {
      return {
        name: [vName(), vLength(2, 30)],
        email: [vEmail(), this.userEmail ? vRequired() : () => false],
      }
    },
    file() {
      return this.files?.[0] ?? null
    },
    progress() {
      return this.fileProgress(this.file?.id)
    },
    fileURL() {
      return this.file?.url ?? null
    },
    isChanged() {
      return (
        this.formData.name !== this.clientName ||
        this.formData.email !== this.userEmail ||
        this.formData.avatar?.content_url !== this.clientAvatar('content_url') ||
        this.formData.avatar?.preview_url !== this.clientAvatar('preview_url')
      )
    },
  },
  methods: {
    ...mapActions('Client', ['putClient']),
    ...mapActions('Media', ['postMedia']),
    ...mapMutations('Page', ['setPageTitle']),
    ...mapMutations('Files', ['setFileProgress']),
    ...mapMutations('Toasts', ['pushToast']),
    ...mapMutations('User', ['setUserEmail']),
    ...mapMutations('Client', ['setGPTVersion']),
    onAvatar() {
      this.formData.avatar.content_url = this.fileURL
      this.formData.avatar.preview_url = this.fileURL
    },
    onCancel() {
      this.resetData()
      this.$bus.$emit('back-button')
    },
    resetData() {
      this.formData.name = this.clientName
      this.formData.email = this.userEmail
      this.formData.avatar.content_url = this.clientAvatar('content_url')
      this.formData.avatar.preview_url = this.clientAvatar('preview_url')
      this.formData.avatar_id = null
    },
    async onEditClient() {
      await this.postMedia(this.setFileProgress)
      if (this.formData.avatar.content_url) {
        this.formData.avatar_id = this.mediaIds?.[0]
      }
      const { avatar_id, name, email } = this.formData
      const client = await this.putClient({
        id: this.clientId,
        client: { avatar_id, name, email },
      })
      if (!client?.id) {
        this.isValid = false
        return
      }
      if (this.user) {
        const emailTrimmed = email?.trim ? email.trim() : email
        this.setUserEmail(emailTrimmed)
      }
      this.resetData()
      this.pushToast({
        type: 'success',
        title: `${this.$t('success.profile.edit.title')}`,
        text: `${this.$t('success.profile.edit.text')}`,
      })
    },
  },
})
</script>

<style lang="scss" module>
.form {
  display: grid;
  grid-template-rows: 1fr min-content;
  height: 100%;
  padding: 15px;
  row-gap: 15px;
}

.container {
  display: grid;
  grid-auto-rows: min-content;
  row-gap: 15px;
  color: $secondary;
}

.title {
  width: 100%;
  padding-left: 3px;
  overflow: hidden;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.avatar {
  display: grid;
  row-gap: 6px;
}

.image {
  width: 90px;
  height: 90px;
  object-fit: cover;
  border-radius: 50%;
}

.buttons {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 6px;
}

.id {
  display: grid;
  grid-gap: 6px;
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;

  & .descr {
    padding-left: 3px;
    font-size: 12px;
    line-height: 12px;
  }
}
</style>
