// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ClientEdit_form_3howB{grid-template-rows:1fr min-content;height:100%;padding:15px}.ClientEdit_container_2_Fhl,.ClientEdit_form_3howB{display:grid;row-gap:15px}.ClientEdit_container_2_Fhl{grid-auto-rows:min-content;color:var(--supster-secondary)}.ClientEdit_title_3JKh-{width:100%;padding-left:3px;overflow:hidden;font-weight:400;font-size:12px;line-height:12px;white-space:nowrap;text-overflow:ellipsis}.ClientEdit_avatar_AqmTR{display:grid;row-gap:6px}.ClientEdit_image_2Gghx{width:90px;height:90px;-o-object-fit:cover;object-fit:cover;border-radius:50%}.ClientEdit_buttons_I4Rqr{grid-auto-flow:column}.ClientEdit_buttons_I4Rqr,.ClientEdit_id_3J5GI{display:grid;grid-gap:6px}.ClientEdit_id_3J5GI{font-weight:400;font-size:15px;line-height:20px}.ClientEdit_id_3J5GI .ClientEdit_descr_1zrJH{padding-left:3px;font-size:12px;line-height:12px}", ""]);
// Exports
exports.locals = {
	"form": "ClientEdit_form_3howB",
	"container": "ClientEdit_container_2_Fhl",
	"title": "ClientEdit_title_3JKh-",
	"avatar": "ClientEdit_avatar_AqmTR",
	"image": "ClientEdit_image_2Gghx",
	"buttons": "ClientEdit_buttons_I4Rqr",
	"id": "ClientEdit_id_3J5GI",
	"descr": "ClientEdit_descr_1zrJH"
};
module.exports = exports;
